import React, { useEffect, useState } from "react";
import { TitlePage } from "@components/commun/TitlePage/TitlePage";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import {
  CHANNEL_CODES,
  EFieldTypes,
  EType,
  FieldPropsType,
  ICountry,
} from "@middleware/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGlobalMessaging, useSchemaMessages } from "@middleware/hooks";
import * as yup from "yup";
import { isValidLandlinePhone, isValidMobilePhone } from "@middleware/helpers";
import { useTranslation } from "next-i18next";
import Field from "@components/ui/Forms/Field/Field";
import { Button, Loader, Text } from "@components/ui";
import Api from "@middleware/api";
import { FORM_PHONE_KEY, LOADER_COLOR } from "@middleware/constants";
import style from "../ModalCallMe.module.scss";

export default function CallMeForm() {
  const { t } = useTranslation();
  const { setMessage } = useGlobalMessaging();
  const [countries, setCountries] = useState<ICountry[]>();
  const messages = useSchemaMessages();
  const [phoneSent, setPhoneSent] = useState(false);
  const defaultValue = {
    phone: "",
  };
  const customSchema = yup.object().shape({
    phone: yup
      .string()
      .required(messages.requiredField)
      .test(
        "validLandlinePhone",
        messages.invalidPhoneMobile as string,
        (value) =>
          isValidMobilePhone(value, true) || isValidLandlinePhone(value, true),
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "onBlur",
    defaultValues: defaultValue,
    resolver: yupResolver<FieldValues>(customSchema),
  });
  useEffect(() => {
    const getCountries = async () => {
      const channel = await Api.cart.getChannel(CHANNEL_CODES.KITCHEN_DAILY);
      setCountries(channel.countries);
    };
    void getCountries();
  }, []);
  const fieldProps: FieldPropsType<string, React.ReactElement> = {
    name: FORM_PHONE_KEY,
    register,
    label: t("form.telephone"),
    control,
    placeholder: "+33 6 03 05 06 07",
    type: EFieldTypes.TEL,
    error: errors[FORM_PHONE_KEY]?.message as string,
    wrapperClass: style.field,
    className: "input-text",
    countryCode: countries ? countries[0].code.toLocaleLowerCase() : "",
    availableCountries: countries
      ? countries.map((country) => country.code.toLocaleLowerCase())
      : [],
  };

  const onSubmit: SubmitHandler<FieldValues> = async (datas) => {
    const response = await Api.customer.setPhoneNumber(datas.phone);
    if (response) {
      setPhoneSent(true);
    } else {
      setMessage({
        text: t("error.sorryMessage"),
        type: EType.ERROR,
      });
    }

    return datas;
  };

  return countries !== undefined ? (
    <div className={style.wrapper}>
      <TitlePage className={style.title}>
        {t("pages.shareYouPhoneNumberAndWeWillContactYou")}
      </TitlePage>
      {!phoneSent ? (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Field props={fieldProps} variant={fieldProps.type as EFieldTypes} />
          <div className={style.action}>
            <Button type="submit">{t("buttons.toBeCalledBack")}</Button>
          </div>
        </form>
      ) : (
        <Text className={style.message}>
          {t("pages.thankYouWeWewillContactYouWithinTwoMinutes")}
        </Text>
      )}
    </div>
  ) : (
    <div className={style.loader}>
      <Loader type="fade" color={LOADER_COLOR} />
    </div>
  );
}
