import {
  EOrderType,
  EPaymentMethods,
  EProgramType,
  IBundleInfo,
  IConfiguration,
  ICustomer,
  ILocalStorageCart,
  IOrder,
  IOrderItem,
  IProduct,
  IProgram,
  IRegularisationOrder,
  IRelayPoints,
  ISchedule,
} from "@middleware/types";
import {
  ADMINISTRATION_FEES,
  BUNDEL_COMPLEMENT_CODE,
  BUNDEL_MEALS_CHOCO,
  BUNDEL_MEALS_DESSERT,
  BUNDEL_MEALS_THE,
  BUNDLE_ATTRIBUTE_DAYS,
  BUNDLE_BREAKFAST_CODE,
  BUNDLE_SNACK_CODE,
  BUNDLE_STAB_ATTRIBUTE_CODE,
  BUNDLE_TAXON_CONFIGURATION,
  CART_TOKEN_STORAGE,
  CODE_BUNDLE_MAIN_TAXON,
  CODE_LOYALTY,
  CODE_MEAL_MAIN_TAXON,
  ORDER_ECO_CODE,
  ORDER_OPTIMUM_CODE,
  ORDER_ORIGINAL_CODE,
  ORDER_ORIGINAL_FRESH_CODE,
  URL_PAGE_SUCCESS_ALC,
  URL_PAGE_SUCCESS_OPTIMUM,
  URL_PAGE_SUCCESS_ORIGINAL,
  URL_PAGE_SUCCESS_STAB,
} from "@middleware/constants";
import Api from "@middleware/api";
import { client as env } from "@config/env/client";
import {
  generateDate,
  getItemSku,
  getPreciseSlot,
  getRelayPointsAddress,
  getRelayPointsSchedules,
} from "./utils";
import { getFromStorage } from "./sessions";
import { getBundleProgramType, getConfigurationsMeals } from "./catalog";

export const getShippingMethodValues = (
  shippingMethods: ISchedule,
  currentDay: string,
) => {
  if (!(currentDay in shippingMethods)) return [];

  return shippingMethods[currentDay].shipping_methods
    .sort((a, b) => (a.position > b.position ? 1 : -1))
    .map((method) => ({
      label: method.description,
      value: method.code,
      type: method.type,
    }));
};

export const getNextOrderToken = (currentOrder: IOrder, orders?: IOrder[]) => {
  if (!orders) return currentOrder.tokenValue;

  const currentDate = generateDate(currentOrder.chosenDeliveryDate);

  const nextOrder = orders.find(
    (order) => generateDate(order.chosenDeliveryDate) > currentDate,
  );

  return nextOrder ? nextOrder.tokenValue : currentOrder.tokenValue;
};

export const getFormattedAddress = (order: IOrder) => {
  const shippingAddress = order.shippingAddress;
  const shipmentAddressLine1 = shippingAddress
    ? shippingAddress.street +
      (shippingAddress.complement !== undefined
        ? ", " + shippingAddress.complement
        : "")
    : "";

  const shipmentAddressLine2 = shippingAddress
    ? shippingAddress.postcode + ", " + shippingAddress.city
    : "";

  return { shipmentAddressLine1, shipmentAddressLine2 };
};

export const getShippingSlotsValues = (
  shippingMethods: ISchedule,
  currentDay: string,
) => {
  if (!(currentDay in shippingMethods)) return [];

  return shippingMethods[currentDay].delivery_slots?.map((slot) => ({
    label: getPreciseSlot(slot.date),
    value: slot,
  }));
};

export const getShippingRelayPointsValues = (
  shippingMethods: ISchedule,
  currentDay: string,
) => {
  return typeof shippingMethods[currentDay] === "object"
    ? shippingMethods[currentDay].chronopost_relay_points?.map((relay) => ({
        label: relay.nom,
        value: relay.identifiant,
      }))
    : [];
};

export const getRelayPointByIdentifiant = (
  shippingMethods: ISchedule,
  currentDay: string,
  identifiant: string,
) => {
  const relayPoints = shippingMethods[currentDay].chronopost_relay_points;

  return relayPoints?.find((relay) => relay.identifiant === identifiant);
};

export const getShippingRelayPointByIdentifiant = (
  shippingMethods: ISchedule,
  currentDay: string,
  identifiant: string,
) => {
  const deliveryRelayPoints = getShippingRelayPointsValues(
    shippingMethods,
    currentDay,
  );

  return deliveryRelayPoints?.find((relay) => relay.value === identifiant);
};

export const getRelayPointsAdressess = (relayPoints: IRelayPoints[]) => {
  return relayPoints.map((relay) => ({
    nom: relay.nom,
    address: getRelayPointsAddress(relay),
    schedules: getRelayPointsSchedules(relay),
    longitude: parseFloat(relay.coordGeolocalisationLongitude),
    latitude: parseFloat(relay.coordGeolocalisationLatitude),
    identifiant: relay.identifiant,
  }));
};

export const getShippingRelayPoints = (
  shippingMethods: ISchedule,
  currentDay: string,
) => {
  const relayPoints = shippingMethods[currentDay].chronopost_relay_points;

  return relayPoints !== undefined ? getRelayPointsAdressess(relayPoints) : [];
};

export const getOrderLoyalty = (order: IOrder) => {
  return (
    order.adjustments.find(
      (adjustment) => adjustment.originCode === CODE_LOYALTY,
    ) ?? null
  );
};

export const getTotalWithoutDiscount = (order: IOrder) => {
  const discount = order.orderPromotionTotal;

  return order.total - discount;
};

export const getCodeBundleOfOrder = (order: IOrder) => {
  return order.items.find(
    (item) => item.productTypeTaxon === CODE_BUNDLE_MAIN_TAXON,
  )?.variantCode as string;
};

export const getOrderBundleItem = (order: IOrder) => {
  return order.items.find(
    (item) => item.productTypeTaxon === CODE_BUNDLE_MAIN_TAXON,
  );
};
export const getOrderItems = (order: IOrder) => {
  return order.items.filter(
    (item) => item.productTypeTaxon !== CODE_BUNDLE_MAIN_TAXON,
  );
};

export const getMealsTaxonFromOrder = (order: IOrder): string[] => {
  const orderBundle = getOrderBundleItem(order);
  if (!orderBundle) return [];

  const configExtra = orderBundle.product.attributes.find(
    (attr) => attr.attributeCode === BUNDLE_TAXON_CONFIGURATION,
  );
  if (!configExtra) return [];

  if (
    typeof configExtra.value === "object" &&
    Array.isArray(configExtra.value.values)
  ) {
    return configExtra.value.values
      .filter((val) => val.asMeal === true)
      .map((item) => item.extra);
  }

  return [];
};

export const getFormulasMealsNumber = (order: IOrder) => {
  const codeBundle = getCodeBundleOfOrder(order);

  return parseInt(codeBundle.split("_")[1]);
};

export const updateCartFromStorage = async (
  cart: IOrder | undefined,
  setCart: (order: IOrder) => void,
) => {
  const cartToken = getFromStorage(CART_TOKEN_STORAGE) as
    | ILocalStorageCart
    | undefined;
  if (cart && cart.tokenValue !== cartToken?.token) {
    const currentOrder = await Api.cart.getCartByToken(
      cartToken?.token as string,
    );
    setCart(currentOrder);
  }
};

export const getPriceBundleOfOrder = (order: IOrder) => {
  return (
    order.items.find((item) => item.productTypeTaxon === CODE_BUNDLE_MAIN_TAXON)
      ?.unitPrice ?? 0
  );
};

export const getBundleInfos = (
  orderItem: IOrderItem | undefined,
): IBundleInfo => {
  if (orderItem) {
    const typeProgram = getBundleProgramType(orderItem.product.productTaxons);
    const daysProgram = orderItem.product.attributes.find(
      (attribute) => attribute.attributeCode === BUNDLE_ATTRIBUTE_DAYS,
    )?.value;

    return {
      typeProgram,
      daysProgram,
    };
  }

  return {
    typeProgram: "",
    daysProgram: "",
  };
};

export const isMegratedPaybox = (order: IOrder) => {
  if (order.subscription) return order.subscription.isMigratedPayboxData;

  return false;
};
export const getRemainingDeferral = (order: IOrder) => {
  if (order.subscription) return order.subscription.remainingDeferral;

  return 0;
};
export const getEnabledPaymentMethods = () => {
  return [
    EPaymentMethods.CREDIT_CARD,
    EPaymentMethods.SEPA,
    EPaymentMethods.PAYBOX,
  ];
};

export const getShipmentTrackingUrl = (shipmentTracking: string) => {
  return (
    env.NEXT_PUBLIC_CHRONOPOST_TRACKING + "?listeNumerosLT=" + shipmentTracking
  );
};

export const getOrderSuccessUrl = (orderItem?: IOrderItem, isAlc?: boolean) => {
  if (isAlc === true) return URL_PAGE_SUCCESS_ALC;

  const orderInfo = getBundleInfos(orderItem);

  return orderInfo.typeProgram === EProgramType.OPTIMUM
    ? URL_PAGE_SUCCESS_OPTIMUM
    : orderInfo.typeProgram === EProgramType.STAB
    ? URL_PAGE_SUCCESS_STAB
    : URL_PAGE_SUCCESS_ORIGINAL;
};

export const isOptimuSubscription = (customer: ICustomer | undefined) => {
  if (!customer) return false;

  return customer.currentSubscription?.bundleType === ORDER_OPTIMUM_CODE;
};

export const isStabOrder = (order: IOrder | undefined) => {
  if (!order) return false;
  if (order.items.length === 0) return false;

  return order.items.some((item) =>
    item.product.attributes.some(
      (attribute) =>
        Array.isArray(attribute.value) &&
        // @ts-ignore: Unreachable code error
        attribute.value.includes(BUNDLE_STAB_ATTRIBUTE_CODE),
    ),
  );
};

export const isOriginalOrder = (order: IOrder | undefined) => {
  if (!order) return false;
  if (order.items.length === 0) return false;

  return order.items.some((item) =>
    item.product.attributes.some(
      (attribute) =>
        Array.isArray(attribute.value) &&
        // @ts-ignore: Unreachable code error
        attribute.value.includes(ORDER_ORIGINAL_CODE),
    ),
  );
};

export const isEcoOrder = (order: IOrder | undefined) => {
  if (!order) return false;
  if (order.items.length === 0) return false;

  return order.items.some((item) =>
    item.product.attributes.some(
      (attribute) =>
        Array.isArray(attribute.value) &&
        // @ts-ignore: Unreachable code error
        attribute.value.includes(ORDER_ECO_CODE),
    ),
  );
};
export const isCateringOrder = (order: IOrder | undefined) => {
  if (!order) return false;
  if (order.items.length === 0) return false;

  return order.items.some(
    (item) =>
      item.product.attributes.some(
        (attribute) =>
          Array.isArray(attribute.value) &&
          // @ts-ignore: Unreachable code error
          attribute.value.includes(ORDER_ORIGINAL_CODE),
      ) && item.productCode.includes(ORDER_ORIGINAL_FRESH_CODE),
  );
};

export const isOptimumOrder = (order: IOrder | undefined) => {
  if (!order) return false;
  if (order.items.length === 0) return false;

  return order.items.some((item) =>
    item.product.attributes.some(
      (attribute) =>
        Array.isArray(attribute.value) &&
        // @ts-ignore: Unreachable code error
        attribute.value.includes(ORDER_OPTIMUM_CODE),
    ),
  );
};

export const sortOrders = (
  orders: (IOrder | IRegularisationOrder)[],
  ordre = "ASC",
): (IOrder | IRegularisationOrder)[] => {
  return ordre === "ASC"
    ? orders.sort((a, b) =>
        // @ts-ignore: Unreachable code error
        a.chosenDeliveryDate > b.chosenDeliveryDate ? 1 : -1,
      )
    : orders.sort((a, b) =>
        // @ts-ignore: Unreachable code error
        a.chosenDeliveryDate < b.chosenDeliveryDate ? 1 : -1,
      );
};

export const getUseDifferentBillingAddressDefaultValue = (cart: IOrder) => {
  return (
    cart.billingAddress?.street !== cart.shippingAddress?.street ||
    cart.billingAddress?.complement !== cart.shippingAddress?.complement ||
    cart.billingAddress?.postcode !== cart.shippingAddress?.postcode ||
    cart.billingAddress?.city !== cart.shippingAddress?.city
  );
};

export const serializeCartTypeOfPrismic = (
  prismicField: string,
): EProgramType | EOrderType | null => {
  if (prismicField === "Alc") return EOrderType.ALC;
  if (prismicField === "React") return EOrderType.RESUME;
  if (prismicField === "Subscription") return EOrderType.SUBSCRIPTION;
  if (prismicField === "Catering Program") return EProgramType.ORIGINAL_FRESH;

  return null;
};
export const getSelectedProgramOfPrismic = (
  prismicField: string,
): EProgramType | null => {
  if (prismicField === "Original") return EProgramType.ORIGINAL;
  if (prismicField === "Optimum") return EProgramType.OPTIMUM;
  if (prismicField === "Original Frais") return EProgramType.ORIGINAL_FRESH;
  if (prismicField === "Stab") return EProgramType.STAB;
  if (prismicField === "Eco") return EProgramType.ECO;

  return null;
};

export const getOrderOffered = (order: IOrder) => {
  const offeredItems = order.displayedOfferedItems;
  if (!offeredItems) return { offeredItems: [], offeredCount: 0 };

  const offeredCount =
    offeredItems.length > 0
      ? offeredItems.reduce(
          (accum, item) =>
            (accum += item.quantity * (item.productPackaging ?? 1)),
          0,
        )
      : 0;

  return { offeredItems, offeredCount };
};

export const hasMissedMeals = (
  order: IOrder | undefined,
  currentMeals: IProduct[],
) => {
  if (order === undefined) return false;

  const orderMeals = getOrderItems(order).filter(
    (item) =>
      currentMeals.filter((meals) => meals.code === getItemSku(item)).length >
      0,
  );

  return orderMeals.length !== getOrderItems(order).length;
};

export const getShipmentNumber = (order: IOrder | undefined) => {
  if (order === undefined) return "";
  if (order.shipments.length === 0) return "";

  return order.shipments[0].number ?? "";
};
export const hasOrderItemTaxon = (item: IOrderItem, codeTaxon: string) => {
  return (
    item.product.productTaxons.find(
      (taxon) => taxon.taxon.code === codeTaxon,
    ) !== undefined
  );
};
export const getReguleApplicationFees = (order: IOrder | undefined) => {
  if (order === undefined) return 0;

  const feesItem = order.items.find(
    (item) => item.productCode === ADMINISTRATION_FEES,
  );

  if (feesItem) {
    return feesItem.total;
  }

  return 0;
};

export const isValidShipment = (
  order: IOrder | undefined,
  currentBundle: IProgram,
  configurations: IConfiguration[],
) => {
  if (order === undefined) return true;
  if (order.items.length === 0) return false;

  const orderQuantities = {
    [CODE_MEAL_MAIN_TAXON]: 0,
    [BUNDLE_BREAKFAST_CODE]: 0,
    [BUNDLE_SNACK_CODE]: 0,
    [BUNDEL_MEALS_DESSERT]: 0,
    [BUNDEL_MEALS_CHOCO]: 0,
    [BUNDEL_MEALS_THE]: 0,
    [BUNDEL_COMPLEMENT_CODE]: 0,
  };

  order.items.map((item) => {
    switch (true) {
      case hasOrderItemTaxon(item, CODE_MEAL_MAIN_TAXON):
        orderQuantities[CODE_MEAL_MAIN_TAXON] +=
          item.quantity * (item.productPackaging ?? 1);
        break;
      case hasOrderItemTaxon(item, BUNDLE_BREAKFAST_CODE):
        orderQuantities[BUNDLE_BREAKFAST_CODE] +=
          item.quantity * (item.productPackaging ?? 1);
        break;
      case hasOrderItemTaxon(item, BUNDLE_SNACK_CODE):
        orderQuantities[BUNDLE_SNACK_CODE] +=
          item.quantity * (item.productPackaging ?? 1);
        break;
      case hasOrderItemTaxon(item, BUNDEL_MEALS_DESSERT):
        orderQuantities[BUNDEL_MEALS_DESSERT] +=
          item.quantity * (item.productPackaging ?? 1);
        break;
      case hasOrderItemTaxon(item, BUNDEL_MEALS_CHOCO):
        orderQuantities[BUNDEL_MEALS_CHOCO] +=
          item.quantity * (item.productPackaging ?? 1);
        break;
      case hasOrderItemTaxon(item, BUNDEL_MEALS_THE):
        orderQuantities[BUNDEL_MEALS_THE] +=
          item.quantity * (item.productPackaging ?? 1);
        break;
      case hasOrderItemTaxon(item, BUNDEL_COMPLEMENT_CODE):
        orderQuantities[BUNDEL_COMPLEMENT_CODE] +=
          item.quantity * (item.productPackaging ?? 1);
        break;

      default:
        break;
    }

    return item;
  });

  const configurationsMeals = getConfigurationsMeals(
    currentBundle,
    configurations,
  );

  return configurationsMeals.every((config) =>
    config.mealsNumber.includes(
      orderQuantities[config.code as keyof typeof orderQuantities],
    ),
  );
};
