import { ESubscriptionState, ICustomer, IOrder } from "@middleware/types";
import { useEffect, useState } from "react";
import { useAuth } from "./useAuth";
import { useCart } from "./useCart";

export const useCustomer = () => {
  const { user, customer } = useAuth();
  const { cart } = useCart();
  const [currentCustomer, setCustomer] = useState<ICustomer>();
  const [currentOrder, setCurrentOrder] = useState<IOrder>();
  const [lastOrder, setLastOrder] = useState<IOrder>();

  useEffect(() => {
    const getCustomer = async () => {
      if (user && customer) {
        const newCustomer = { ...customer, id: user.id };
        setCustomer(newCustomer);
        if (
          customer.currentSubscription &&
          customer.currentSubscription.state !== ESubscriptionState.CLOSED
        ) {
          if (
            customer.currentSubscription.state === ESubscriptionState.ON_GOING
          ) {
            setCurrentOrder(customer.currentSubscription.firstUpcomingOrder);
          } else {
            setCurrentOrder(cart);
          }
        }
        if (Object.values(customer.allUpcomingOrders).length > 0) {
          const lastCustomerOrder = Object.values(
            customer.allUpcomingOrders,
          ).pop();
          setLastOrder(lastCustomerOrder);
        }
      }
    };

    void getCustomer();
  }, [user, customer, cart]);

  return { customer: currentCustomer, setCustomer, currentOrder, lastOrder };
};
