import React, { FC, ReactNode } from "react";

interface Props {
  children: ReactNode;
  onClick: () => void;
  tag?: "span" | "div";
  className?: string;
}

export const Pressable: FC<Props> = ({
  tag: Tag = "span",
  children,
  onClick,
  className,
}) => {
  return (
    <Tag className={className} role="presentation" onClick={onClick}>
      {children}
    </Tag>
  );
};
