import React, { useEffect, useState } from "react";
import { Text } from "@components/ui";
import { useCustomer } from "@middleware/hooks";
import { PrismicRichText } from "@prismicio/react";
import { useRouter } from "next/router";
import {
  URL_PAGE_CUSTOMER_ORDERS_COMING,
  URL_PAGE_CUSTOMER_PAYMENT,
  URL_PAGE_SHIPMENT_DETAILS,
} from "@middleware/constants";
import { useUnpaidOrder } from "@middleware/hooks/useUnpaidOrder";
import { getIfCanPayReguleOrder } from "@middleware/helpers";
import style from "./PaymentReminderBanner.module.scss";

export default function PaymentReminderBanner() {
  const { customer } = useCustomer();
  const [showBanner, setShowBanner] = useState(false);
  const { issueOrder, message, reguleOrder, closedSubscriptionNoPaid } =
    useUnpaidOrder(customer);
  const router = useRouter();
  const isPaymentPage =
    router.pathname === URL_PAGE_CUSTOMER_PAYMENT + "/[token]";
  const hasReguleOrder = reguleOrder !== undefined;
  const canPayReguleOrder = getIfCanPayReguleOrder(reguleOrder);
  const redirectionUrl = hasReguleOrder
    ? `${URL_PAGE_CUSTOMER_ORDERS_COMING}`
    : `${URL_PAGE_SHIPMENT_DETAILS}${issueOrder?.tokenValue ?? ""}`;

  const customSerializers = {
    hyperlink: (props: any) => {
      const { node, children } = props;

      return (
        <a
          href={node.data.url}
          onClick={(e) => {
            e.preventDefault();
            router.push(redirectionUrl);
          }}
        >
          {children}
        </a>
      );
    },
  };

  useEffect(() => {
    setShowBanner(
      !isPaymentPage &&
        message !== undefined &&
        (issueOrder !== undefined || canPayReguleOrder) &&
        customer !== undefined,
    );
  }, [
    isPaymentPage,
    message,
    issueOrder,
    customer,
    closedSubscriptionNoPaid,
    canPayReguleOrder,
  ]);

  return showBanner ? (
    <div className={style.banner}>
      <div className="lk-wrap">
        <div className={style.flex}>
          <div className={style.text}>
            <Text variant="body">
              <PrismicRichText field={message} components={customSerializers} />
            </Text>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
