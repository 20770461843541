import { EPaymentState, ICustomer, IOrder } from "@middleware/types";
import { Content } from "@prismicio/client";
import { useEffect, useState } from "react";
import { getIsIssuerOrder, getIssueMessage } from "@middleware/helpers";
import { RichTextField } from "@prismicio/types";
import { createClient } from "../../../prismicio";
import { useOrders } from "./useOrders";

export const useUnpaidOrder = (customer: ICustomer | undefined) => {
  const [issueOrder, setIssueOrder] = useState<IOrder>();
  const [reguleOrder, setReguleOrder] = useState<IOrder>();
  const [message, setMessage] = useState<RichTextField>();
  const [closedSubscriptionNoPaid, setClosedSubscriptionNoPaid] =
    useState<boolean>();
  const [banner, setBanner] = useState<Content.PaymentreminderbannerDocument>();
  const { orders, loading } = useOrders();

  useEffect(() => {
    const getReguleAndIssueOrder = () => {
      const getOrders = async () => {
        if (orders === undefined) return;

        const unpaidOrder = orders.find(
          (order) => getIsIssuerOrder(order) && order.regularisation !== true,
        );
        const regule = orders.find((order) => order.regularisation === true);

        setReguleOrder(regule);
        setIssueOrder(unpaidOrder);
      };
      void getOrders();
    };

    void getReguleAndIssueOrder();
  }, [customer, orders]);

  useEffect(() => {
    const getMessages = async () => {
      const client = createClient();
      const bannerData = await client.getSingle("paymentreminderbanner");
      setBanner(bannerData);
    };
    void getMessages();
  }, []);

  useEffect(() => {
    const customerPaymentState = customer?.currentSubscription?.paymentState;
    const isClosedSubscriptionNoPaid =
      customerPaymentState === EPaymentState.CLOSED_AUTOMATICALLY ||
      customerPaymentState === EPaymentState.CLOSED_MANUALLY;
    const messageState = isClosedSubscriptionNoPaid
      ? customerPaymentState
      : issueOrder
      ? issueOrder.paymentState
      : "";
    const newMessage =
      banner !== undefined &&
      (issueOrder !== undefined || isClosedSubscriptionNoPaid)
        ? getIssueMessage(banner.data, messageState)
        : undefined;

    setMessage(newMessage);
    setClosedSubscriptionNoPaid(isClosedSubscriptionNoPaid);
  }, [banner, customer, issueOrder]);

  return {
    issueOrder,
    setIssueOrder,
    reguleOrder,
    setReguleOrder,
    message,
    setMessage,
    loadingIssueOrder: loading,
    closedSubscriptionNoPaid,
  };
};
