import { ValueOf } from "next/dist/shared/lib/constants";
import { IObject } from "./communsType";

export enum ECalendarHourStatus {
  OPEN = "open",
  CALL = "call",
  INCOMING = "incoming",
  CLOSED = "closed",
}
export type TCalendarHourStatus = ValueOf<typeof ECalendarHourStatus>;
export interface ICalendarHour {
  value: string;
  status: TCalendarHourStatus;
  active?: boolean;
}
export interface ICalendarDay {
  value: string;
  hours: ICalendarHour[];
  page: number;
  collapsed: boolean;
}
export interface ICalendarDataSlots {
  [name: string]: IObject;
}
export interface ICalendarDataApi {
  appointment_credit: number;
  calendar: IObject;
  cancelled_appointment_count: number;
  has_diet: boolean;
}
export type TDiet = {
  lastAppointment: string;
  isFirstAppointment: boolean;
  currentAppointmentDate: string | null;
  canTake: boolean;
  hasDiet: boolean;
  hasCurrentAppointment: boolean;
  appointmentCredit: number;
  cancelledAppointmentCount: number;
  currentAppointment: TAppointment;
  diet: TDietitian | null;
};
export type TAppointment = {
  id: number;
  date: string;
  startTime: string;
  endTime: string;
};
export type TAppointmentApiData = {
  last_appointment: string;
  is_first_appointment: boolean;
  current_appointment_date: string | null;
  can_take: boolean;
  has_diet: boolean;
  has_current_appointment: boolean;
  appointment_credit: number;
  cancelled_appointment_count: number;
  current_appointment: {
    id: number | null;
    date: {
      time: string | null;
    } | null;
  };
  diet: TDietitian | null;
};

export type TDietitian = {
  id: number;
  firstname: string;
  lastname: string;
  description: string;
  picture: string | null;
};
