import { EPaymentState } from "@middleware/types";

export const BUNDLE_PROGRAM_DAYS = "program_days";
export const BUNDLE_ORIGINAL_TAXON_CODE = "original_program";
export const BUNDLE_ECO_TAXON_CODE = "eco_program";
export const BUNDLE_OPTIMUM_TAXON_CODE = "optimum_program";
export const BUNDLE_STAB_TAXON_CODE = "stabilisation_program";
export const BUNDLE_ORIGINAL_FRAIS_TAXON_CODE = "original_frais_program";
export const BUNDLE_STAB_ATTRIBUTE_CODE = "stabilisation";
export const BUNDLE_ATTRIBUTE_TYPE = "bundle_type";
export const VARIANT_CODE_MIN_WEIGHT = "min_weight_loss";
export const VARIANT_CODE_MAX_WEIGHT = "max_weight_loss";
export const BUNDLE_CODE_MEALS = "min_meals_number";
export const BUNDLE_CODE_MEALS_MAX = "max_meals_number";
export const BUNDLE_CODE_DEFAULT = "is_default";
export const BUNDLE_CHECKOUT_ENABLED = "checkout_enabled";
export const BUNDLE_TAXON_CONFIGURATION = "extra_taxon_configuration";
export const BUNDLE_EXTRA_QUANTITY = "extra_taxon_quantities";
export const BUNDLE_CODE_DEFAULT_COUPON_CODE = "default_coupon_code";
export const MEAL_CODE_NUTRISCORE = "nutri_score";
export const MEAL_CODE_FOOD_PREFERENCE = "food_preference";
export const MEAL_CODE_INGREDIENT_DESCRIPTION = "ingredient_description";
export const MEAL_CODE_NUTRITIONAL_VALUE = "nutritional_value";
export const MEAL_CODE_PREPARATION_ADVICE = "preparation_advice";
export const CODE_BUNDLE_MAIN_TAXON = "bundle";
export const CODE_MEAL_MAIN_TAXON = "meal";
export const CODE_EXTRA_MAIN_TAXON = "extra";
export const CODE_LOYALTY = "CREDIT";
export const MEAL_CODE_STORAGE_INSTRUCTIONS = "storage_instructions";
export const OBJECTIF_STEP_SECTION = 1;
export const MENU_STEP_SECTION = 2;
export const FICHES_STEP_SECTION = 2;
export const FICHES2_STEP_SECTION = 3;
export const BUNDLE_BREAKFAST_CODE = "breakfast";
export const BUNDLE_SNACK_CODE = "snack";
export const BUNDEL_MEALS_CODE = "meal";
export const BUNDEL_COMPLEMENT_CODE = "complement";
export const BUNDEL_MEALS_DESSERT = "dessert";
export const BUNDEL_MEALS_CHOCO = "chocolate";
export const BUNDEL_MEALS_THE = "infusion";
export const BUNDLE_INITIAL_CONFIGS = [
  { label: "Plats", label2: "Plats", id: "meal", required: true, priority: -3 },
  {
    label: "petits-déj",
    label2: "petits-déjeuners",
    id: "breakfast",
    required: true,
    priority: -2,
  },
  {
    label: "Collations",
    label2: "Collations",
    id: "snack",
    required: true,
    priority: -1,
  },
];
export const OPTIMUM_DEFAULT_SCORE = 85;
export const MAX_OPTIMUM_MEALS = 48;
export const DEFAULT_PROGRAM_DAYS = 6;
export const PERCENTAGE_TOTAL = 100;
export const GOOD_SCORE_VALUE = 85;
export const SCORES_VALUES = [28, 12, 35, 25];
export const SCORES_BREAK_POINTS = [90, 80, 70, 60, 50, 40, 28, 0];
export const BATTERY_BREAK_POINTS = [90, 60, 28, 0];
export const SCORE_GOOD_HEALTH_VALUE = 91;
export const SCORE_NORMAL_HEALTH_VALUE = 61;
export const SCORES_LABELS = [
  "pages.bad",
  "pages.medium",
  "pages.good",
  "pages.veryGood",
];
export const BATTERY_LEVELS = [
  "pages.fullCharge",
  "pages.highCharge",
  "pages.mediumCharge",
  "pages.lowCharge",
];
export const IMC_LEVELS = [
  "pages.severeObesity",
  "pages.obesity",
  "pages.overweight",
  "pages.normality2",
];
export const IMC_LEVELS_WITH_PREFIX = [
  "pages.severeObesityWithD",
  "pages.obesityWithD",
  "pages.overweightWithD",
  "pages.normality2",
];
export const CONFIG_SUBSCRIPTION_TUNNEL_TYPE = "subscription";
export const CONFIG_RETAIL_TUNNEL_TYPE = "retail";
export const PRODUCT_MAX_INCREMENT = 10;
export const PRODUCT_MAX_INCREMENT_OPTIMUM = 10;
export const RECOMMENDED_PRODUCT_MAX_INCREMENT = 4;
export const ORDER_ORIGINAL_CODE = "original";
export const ORDER_ORIGINAL_FRESH_CODE = "frais";
export const ORDER_OPTIMUM_CODE = "optimum";
export const ORDER_ECO_CODE = "eco";
export const STAB_MEALS_BY_DAY = 4;
export const BUNDLE_ATTRIBUTE_DAYS = "program_days";
export const MEAL_CODE_NEW = "novelty";
export const OFFERED_BREAKFASTS_SNACK = 4;
export const OFFERED_BREAKFASTS_SNACK_FRAIS = 2;
export const DEFAULT_DAYS = 5;
export const RESPONSE_VIOLATIONS_PARAM = "violations";
export const PROD_DIETBON_AND_SPONSORSHIP_PROMO_IDS = [
  36, 35, 196, 197, 198, 199, 205, 206,
];
export const STAGING_DIETBON_AND_SPONSORSHIP_PROMO_IDS = [34, 33];
export const FAILED_PAYMENT_STATES = [
  EPaymentState.FIRST_ISSUE,
  EPaymentState.SECOND_ISSUE,
  EPaymentState.THIRD_ISSUE,
  EPaymentState.FOURTH_ISSUE,
  EPaymentState.FIFTH_ISSUE,
  EPaymentState.SIXTH_ISSUE,
];
