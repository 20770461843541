import Api from "@middleware/api";
import { EStateOrder, IOrder } from "@middleware/types";
import { useEffect, useState } from "react";
import { useCustomer } from "./useCustomer";

export const useOrders = (states?: string, subscriptionId?: number) => {
  const [orders, setOrders] = useState<IOrder[]>();
  const [hasOrders, setHasOrders] = useState<boolean>();
  const [loading, setLoading] = useState(false);
  const { customer } = useCustomer();
  const defaultValidOrdersState = [
    EStateOrder.ORDER_STATE_FULFILLED,
    EStateOrder.ORDER_STATE_NEW,
  ];
  const validOrdersState =
    states !== undefined
      ? [states, EStateOrder.ORDER_STATE_SKIPPED]
      : defaultValidOrdersState;

  useEffect(() => {
    const getOrders = async () => {
      if (!customer) return;
      setLoading(true);
      const customerOrders = await Api.cart.getCustomerOrders(subscriptionId);
      const validOrders = customerOrders.filter((order) =>
        validOrdersState.includes(order.state),
      );
      setOrders(validOrders);
      setHasOrders(validOrders.length > 0);

      setLoading(false);
    };
    void getOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, subscriptionId]);

  return { hasOrders, orders, setOrders, loading };
};
