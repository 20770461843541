import Api from "@middleware/api";
import { isDateOlderThanXDays } from "@middleware/helpers";
import { Content } from "@prismicio/client";
import { createClient } from "prismicio";
import { useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import { useCustomer } from "./useCustomer";
import { useGlobalMessaging } from "./useGlobalMessaging";

export const useMotivationScore = () => {
  const { t } = useTranslation();
  const [canRate, setCanRate] = useState<boolean>(false);
  const [scoreValue, setScoreValue] = useState<number>();
  const [content, setContent] = useState<Content.MotivationscoreDocumentData>();
  const { customer } = useCustomer();
  const { setMessage } = useGlobalMessaging();

  useEffect(() => {
    const getPrismicContent = async () => {
      if (customer?.id === undefined) return null;

      const client = createClient();
      const page = await client.getSingle("motivationscore");
      setContent(page.data);

      const scoreInfo = await Api.customer.getMotivationScore(customer.id);

      if (scoreInfo === false) return;
      if (scoreInfo.note === null) {
        setCanRate(true);

        return;
      }
      if (isDateOlderThanXDays(scoreInfo.updatedAt.date, 6)) {
        setCanRate(true);
      }
    };
    void getPrismicContent();
  }, [customer]);
  const setMotivationScore = async () => {
    if (scoreValue === undefined || customer === undefined) return;

    const response = await Api.customer.setMotivationScore(
      scoreValue,
      customer.id,
    );
    if (response === false)
      setMessage({
        type: "ERROR",
        text: t("error.sorryMessage"),
      });
    else {
      setScoreValue(response.note ?? 0);
      setCanRate(false);
    }
  };

  return {
    canRate,
    content,
    scoreValue,
    setScoreValue,
    setMotivationScore,
  };
};
