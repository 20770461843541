import Api from "@middleware/api";
import { client as env } from "@config/env/client";
import {
  EFieldTypes,
  EOptimumStatus,
  EProgramType,
  ESegmentCustomer,
  ESubscriptionState,
  ICustomer,
  IOptimumQuizApi,
  IOptimumRecommendationsApi,
  IOptimumRecommendationsApiMeals,
  IQuizResponseApi,
} from "@middleware/types";
import {
  QuestionType,
  QuizType,
  QuizTypeSections,
  QuizTypeSurvey,
} from "@middleware/types/global/successTypes";
import { MAX_OPTIMUM_MEALS } from "@middleware/constants";
import { MotivationscoreDocumentData } from "../../../../prismicio-types";

export const updateCustomerFoodPreferences = async (
  currentCustomer: ICustomer | undefined,
  customerFilters: Set<string>,
) => {
  if (currentCustomer === undefined) return;

  const excludedFoodPreferences = Array.from(customerFilters);
  const newCustomer = {
    ...currentCustomer,
    excludedFoodPreferences,
  };
  await Api.customer.updateCustomer(newCustomer);
};

export const isCustomerPaused = (customer: ICustomer | undefined): boolean => {
  if (customer === undefined) return false;

  if (!customer.currentSubscription) return false;

  return ESubscriptionState.PAUSED === customer.currentSubscription.state;
};

export const isActiveCustomer = (customer: ICustomer | undefined): boolean => {
  if (customer === undefined) return false;

  if (!customer.currentSubscription) return false;

  return ESubscriptionState.ON_GOING === customer.currentSubscription.state;
};

export const isCustomerSubscribed = (
  customer: ICustomer | undefined,
): boolean => {
  if (customer === undefined) return false;

  if (!customer.currentSubscription) return false;

  return [
    ESubscriptionState.ON_GOING,
    ESubscriptionState.PAUSED,
    ESubscriptionState.UNPAID,
  ].includes(customer.currentSubscription.state);
};

export const getReferralUrl = (sponsorshipCoupon: string) => {
  const storeUrl = env.NEXT_PUBLIC_STORE_URL;

  return (
    storeUrl +
    `?code=${sponsorshipCoupon}&utm_source=parrainage&utm_medium=link&utm_campaign=site`
  );
};

export const getRemainingReferrals = (
  customer: ICustomer | undefined,
): number => {
  if (customer === undefined) return 0;

  const remainingReferrals =
    (customer.user.maxAllowedSponsorships ?? 0) -
    (customer.user.sponsoredUsersCount ?? 0);

  if (remainingReferrals < 0) return 0;

  return remainingReferrals;
};

export const getIsProspect = (customer: ICustomer | undefined): boolean => {
  return customer === undefined || customer.ordersCount === 0;
};
export const getTaskType = (type: string, idQuestion: string) => {
  // code postal type
  if (idQuestion === "4_22") return EFieldTypes.CODE;

  switch (type) {
    case "single":
    case "boolean":
      return EFieldTypes.RADIO;
    case "number":
      return EFieldTypes.NUMBER_WITH_ARROWS;
    default:
      return EFieldTypes.MULTI_SELECT;
  }
};

export const sortTasks = (tasks: QuizTypeSurvey[]) => {
  const questions: QuestionType[] = [];
  const surveys = tasks.sort((a, b) => (a.index > b.index ? 1 : -1));

  const sections = surveys.map((survey) =>
    survey.sections.sort((a, b) => (a.index > b.index ? 1 : -1)),
  );

  sections.map((section) =>
    section.map((sec) => {
      const sorted = sec.questions.sort((a, b) => (a.index > b.index ? 1 : -1));
      sorted.map((q) => questions.push(q));

      return sec;
    }),
  );

  return questions;
};

export const getTasks = (tasks: IOptimumQuizApi[]): QuizType => {
  const surveys: QuizTypeSurvey[] = [];

  tasks[0].surveys.map((survey) => {
    const sections: QuizTypeSections[] = [];
    survey.sections.map((section) => {
      const quiz: QuestionType[] = [];
      section.questions.map((question) => {
        quiz.push({
          label: question.value,
          type: getTaskType(question.type, question.id),
          allowSingleValue: true,
          options: question.answers.map((answer) => ({
            value: answer.label,
            label: answer.label,
          })),
          name: question.id,
          required: question.required === "1",
          class: "",
          placeholder: "",
          help: question.help,
          helpTitle: question.help_title,
          defaultValue: "",
          numberOfInput: undefined,
          index: parseInt(question.index),
        });

        return question;
      });

      sections.push({
        index: parseInt(section.index),
        questions: quiz,
      });

      return section;
    });

    surveys.push({
      index: parseInt(survey.index),
      sections: sections,
    });

    return survey;
  });

  return {
    taskId: tasks[0].id,
    duration: "30 minutes",
    progressInfoBull: "Indice de travail",
    startFrom: 0,
    questions: sortTasks(surveys),
  };
};

const checkRecommendationsMeals = (
  meals: IOptimumRecommendationsApiMeals[],
) => {
  const recommendations = [...meals];
  const count = recommendations.reduce(
    (acc, item) => (acc += item.quantity),
    0,
  );
  if (count > MAX_OPTIMUM_MEALS) {
    const remainder = count - MAX_OPTIMUM_MEALS;
    meals.map((item, index) => {
      if (index < remainder) recommendations[index].quantity--;

      return item;
    });
  }
  if (count < MAX_OPTIMUM_MEALS) {
    const remainder = MAX_OPTIMUM_MEALS - count;
    meals.map((item, index) => {
      if (index < remainder) recommendations[index].quantity++;

      return item;
    });
  }

  return recommendations;
};

export const getRecommendations = (reco: IOptimumRecommendationsApi) => {
  const recommendationsNeals = checkRecommendationsMeals(reco.recommendation);

  return {
    recommendationStatus: reco.recommendation_status,
    recommendation: recommendationsNeals,
    commandId: reco.command_id,
    userId: reco.user_id,
    score: reco.score,
  };
};

export const getOptimumQuizResponses = (reponses: IQuizResponseApi[]) => {
  return reponses.map((reponse) => {
    return {
      questionId: reponse.question_id,
      answer: reponse.answer,
    };
  });
};

export const getCustomerName = (customer: ICustomer) => {
  return customer.firstName.substring(0, 10);
};

export const isSegmentCustomerProspect = (customer?: ICustomer) => {
  return (
    getIsProspect(customer) ||
    customer?.user.segment === ESegmentCustomer.NONE ||
    customer?.user.segment === ESegmentCustomer.PROSPECT4_6 ||
    customer?.user.segment === ESegmentCustomer.PROSPECT_0 ||
    customer?.user.segment === ESegmentCustomer.PROSPECT_6 ||
    customer?.user.segment === ESegmentCustomer.PROSPECT_366 ||
    customer?.user.segment === ESegmentCustomer.PROSPECT_666 ||
    customer?.user.segment === ESegmentCustomer.PROSPECT_999
  );
};
export const isSegmentCustomerActif = (customer?: ICustomer) => {
  return (
    customer?.user.segment === ESegmentCustomer.ACTIVE_ENGAGED ||
    customer?.user.segment === ESegmentCustomer.ACTIVE_SLIMMING_ENGAGED ||
    customer?.user.segment === ESegmentCustomer.ACTIVE_NO_ENGAGED
  );
};
export const isCustomerReact = (customer?: ICustomer) => {
  return (
    customer?.user.segment === ESegmentCustomer.RETENTION_SLIMMING ||
    customer?.user.segment === ESegmentCustomer.REACT_9 ||
    customer?.user.segment === ESegmentCustomer.REACT_91 ||
    customer?.user.segment === ESegmentCustomer.REACT_121 ||
    customer?.user.segment === ESegmentCustomer.REACT_366 ||
    customer?.user.segment === ESegmentCustomer.REACT_1YEAR ||
    customer?.user.segment === ESegmentCustomer.REACT_2YEAR ||
    customer?.user.segment === ESegmentCustomer.REACT_XYEAR
  );
};
export const canShowMotivationScoreBar = (
  canRate: boolean,
  presmicContent: MotivationscoreDocumentData | undefined,
  customer?: ICustomer,
) => {
  return (
    presmicContent !== undefined &&
    canRate &&
    customer?.currentSubscription?.state === ESubscriptionState.ON_GOING &&
    ((customer.currentSubscription.bundleType === EProgramType.OPTIMUM &&
      customer.optimumFormState === EOptimumStatus.COMPLETE) ||
      customer.currentSubscription.bundleType !== EProgramType.OPTIMUM)
  );
};
