import { PrismicPreview } from "@prismicio/next";
import { PrismicProvider } from "@prismicio/react";
import { appWithTranslation } from "next-i18next";
import Link from "next/link";
import { AppProps } from "next/app";
import { Head } from "@components/commun";
import { ManagedUIProvider } from "@middleware/contexts";
import { AuthProvider, GlobalMessagingProvider } from "@middleware/providers";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { CartProvider } from "@middleware/providers";
import { AppLayoutProps } from "@middleware/types";
import { client as env } from "@config/env/client";
import CoreApp from "../components/CoreApp";
import "@styles/globals.scss";
import "@styles/fonts.css";
import "@styles/datepicker.scss";
import "@styles/payzen.css";
import { createClient, repositoryName } from "../../prismicio";

function DietbonApp({ Component, pageProps }: AppLayoutProps<AppProps>) {
  const Layout = Component.layout;

  return (
    <GoogleOAuthProvider clientId={env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}>
      <GlobalMessagingProvider>
        <Head />
        <CartProvider>
          <ManagedUIProvider>
            <AuthProvider>
              <PrismicProvider
                internalLinkComponent={({ href, ...props }) => (
                  <Link href={href} {...props} />
                )}
                client={createClient()}
              >
                <PrismicPreview repositoryName={repositoryName}>
                  <CoreApp>
                    <>
                      <Layout>
                        <Component {...pageProps} />
                      </Layout>
                    </>
                  </CoreApp>
                </PrismicPreview>
              </PrismicProvider>
            </AuthProvider>
          </ManagedUIProvider>
        </CartProvider>
      </GlobalMessagingProvider>
    </GoogleOAuthProvider>
  );
}

export default appWithTranslation(DietbonApp);
