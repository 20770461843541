import WrapApi from "@middleware/helpers/api/wrapApi.class";
import {
  BundleCodePromotionMap,
  BundlePromotionDetails,
  EProductVariantType,
  IConfiguration,
  IProduct,
  IPromotionCouponsResponse,
} from "@middleware/types";
import {
  getBundleFromResponse,
  getBundlePromotionsFromResponse,
  getConfigurationFromResponse,
  getProductsFromResponse,
  getProductsOfMenu,
  getPromotionCouponFromResponse,
} from "@middleware/helpers";
import { AxiosError, AxiosResponse, HttpStatusCode } from "axios";
import { CONFIG_RETAIL_TUNNEL_TYPE } from "@middleware/constants";

export default class Catalog extends WrapApi {
  bundlesCache: AxiosResponse<unknown> | AxiosResponse<AxiosError> | undefined;
  extrasCache: AxiosResponse<unknown> | AxiosResponse<AxiosError> | undefined;
  promotionCache: IPromotionCouponsResponse | undefined;
  mealsCache: {
    [key: string]: AxiosResponse<unknown> | undefined;
  } = { all: undefined, subscription: undefined, retail: undefined };

  getBundles = async (order = "ASC"): Promise<IProduct[]> => {
    if (this.bundlesCache !== undefined) {
      return getProductsFromResponse(
        this.bundlesCache,
        EProductVariantType.SUBSCRIPTION,
        order,
      );
    }

    const bundlesResponses = await this.getResource(
      "shop/products/type/{type}",
      { type: "bundle" },
    );
    this.bundlesCache = bundlesResponses;

    return getProductsFromResponse(
      bundlesResponses,
      EProductVariantType.SUBSCRIPTION,
      order,
    );
  };
  getStabilisationBundles = async (order = "ASC"): Promise<IProduct[]> => {
    const bundlesResponses = await this.getResource("shop/products", null, {
      "productTypeTaxon.code": "stabilisation_program",
    });

    return getProductsFromResponse(
      bundlesResponses,
      EProductVariantType.SUBSCRIPTION,
      order,
    );
  };
  getBundle = async (code: string): Promise<IProduct> => {
    const bundlesResponses = await this.getResource(`shop/products/${code}`);

    return getBundleFromResponse(bundlesResponses);
  };

  getMeals = async (order = "ASC"): Promise<IProduct[]> => {
    const mealsResponses = await this.getResource("shop/products/type/{type}", {
      type: "meal",
    });

    return getProductsFromResponse(
      mealsResponses,
      EProductVariantType.ALL,
      order,
    );
  };

  getProgramFlexibilityMeals = async (
    param = "current",
    type: EProductVariantType,
  ): Promise<IProduct[]> => {
    if (this.mealsCache[type] !== undefined) {
      return getProductsOfMenu(this.mealsCache[type] as AxiosResponse, type);
    }
    if (type === EProductVariantType.ALC) {
      const mealsResponses = await this.getResource(
        // TODO... l'API est statique
        "shop/menus/current/bundles/original_6_days_with_breakfast_and_snack_1_month",
        {
          param,
        },
      );
      this.mealsCache[type] = mealsResponses;

      return getProductsOfMenu(mealsResponses, type);
    }

    const mealsResponses = await this.getResource("shop/menus/{param}", {
      param,
    });
    this.mealsCache[type] = mealsResponses;

    return getProductsOfMenu(mealsResponses, type);
  };

  getProgramFlexibilityMealsByBundle = async (
    param = "current",
    bundleCode: string,
    type: EProductVariantType,
  ): Promise<IProduct[]> => {
    const cacheMenu = type + bundleCode;
    if (this.mealsCache[type] !== undefined) {
      return getProductsOfMenu(
        this.mealsCache[cacheMenu] as AxiosResponse,
        type,
      );
    }

    const mealsResponses = await this.getResource(
      "shop/menus/{param}/bundles/{bundleCode}",
      {
        param,
        bundleCode,
      },
    );
    this.mealsCache[cacheMenu] = mealsResponses;

    return getProductsOfMenu(mealsResponses, type);
  };

  getProgramOptimumMeals = async (): Promise<IProduct[]> => {
    const mealsResponses = await this.getResource(
      "shop/menus/current/all-products",
    );

    return getProductsOfMenu(
      mealsResponses,
      EProductVariantType.SUBSCRIPTION,
      true,
    );
  };

  getExtras = async (
    type: EProductVariantType,
    order = "ASC",
  ): Promise<IProduct[]> => {
    if (this.extrasCache !== undefined) {
      return getProductsFromResponse(this.extrasCache, type, order);
    }

    const extrasResponses = await this.getResource(
      "shop/products/type/{type}",
      {
        type: "extra",
      },
    );
    this.extrasCache = extrasResponses;

    return getProductsFromResponse(extrasResponses, type, order);
  };

  getAlcProducts = async (): Promise<IProduct[]> => {
    const [meals, extras] = await Promise.all([
      this.getProgramFlexibilityMeals("current", EProductVariantType.ALC),
      this.getExtras(EProductVariantType.ALC),
    ]);

    return [...meals, ...extras].filter(
      (prod) =>
        prod.variants &&
        prod.variants.length > 0 &&
        prod.variants.filter(
          (variant) =>
            variant.type !== EProductVariantType.SUBSCRIPTION &&
            variant.enabled,
        ).length > 0,
    );
  };

  getBundlePromotions = async (): Promise<BundleCodePromotionMap> => {
    const bundlePromotionsResponses = await this.getResource(
      "shop/promotions/bundles",
    );

    return getBundlePromotionsFromResponse(bundlePromotionsResponses);
  };

  getCouponPromotion = async (
    code: string,
  ): Promise<BundlePromotionDetails[] | null> => {
    if (
      this.promotionCache !== undefined &&
      this.promotionCache.code === code
    ) {
      return getPromotionCouponFromResponse(this.promotionCache, code);
    }

    const promotionResponse = await this.getResource(
      `shop/promotion-coupons/${code}/promotion`,
    );

    if (promotionResponse.status === HttpStatusCode.Ok) {
      this.promotionCache = promotionResponse.data as IPromotionCouponsResponse;

      return getPromotionCouponFromResponse(
        promotionResponse.data as IPromotionCouponsResponse,
        code,
      );
    }

    return null;
  };

  getProgramConfiguration = async (): Promise<IConfiguration[] | null> => {
    const configurationResponse = await this.getResource(
      `shop/tab-configurations`,
    );

    return getConfigurationFromResponse(configurationResponse);
  };
  getAlcConfiguration = async (): Promise<IConfiguration[] | null> => {
    const configurationResponse = await this.getResource(
      `shop/tab-configurations`,
    );

    return getConfigurationFromResponse(
      configurationResponse,
      CONFIG_RETAIL_TUNNEL_TYPE,
    );
  };
}
